import * as React from 'react';
import { TFunction, Trans, useTranslation } from 'react-i18next';
import { graphql, PageProps } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { useLocation } from '@reach/router';
import { WHITE_ON_BLUE, WHITE_ON_BLUE_PSEUDO_CLASS } from '../components/Colors';
import DefaultLayout from '../components/layouts/default-layout';
import {
  AllImage,
  PageContextProps,
  GatsbyImageFileWithName,
  SiteQueryProps,
} from '../utils/graphql';

type ButtonProps = {
  href: string;
  text: string;
};

const Button: React.VoidFunctionComponent<ButtonProps> = ({ href, text }) => {
  return (
    <a
      href={href}
      className={`button mx-4 my-5 inline-block border border-solid border-current border-reacteev-blue px-6 py-3 text-base font-medium ${WHITE_ON_BLUE} ${WHITE_ON_BLUE_PSEUDO_CLASS}`}
    >
      {text}
    </a>
  );
};

const Step: React.VoidFunctionComponent<{
  step: string;
  t: TFunction;
  i18nKey: string;
  children?: React.ReactNode;
}> = ({ step, t, i18nKey, children }) => {
  return (
    <li className="mt-20">
      <p className="text-xl md:text-2xl">{step}</p>
      <h3 className="text-left text-2xl font-normal md:text-3xl">
        <Trans t={t} i18nKey={i18nKey}>
          {i18nKey}
        </Trans>
      </h3>
      {children}
    </li>
  );
};

export const query = graphql`
  query {
    allFile(
      filter: { sourceInstanceName: { eq: "images" }, relativeDirectory: { eq: "appStores" } }
    ) {
      nodes {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, height: 56, placeholder: BLURRED)
        }
        name
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`;

type ConsignesVRQueryProps = {
  allFile: AllImage;
  site: SiteQueryProps;
};
type ConsignesVRProps = PageProps<ConsignesVRQueryProps, PageContextProps>;

const ConsignesVR: React.VoidFunctionComponent<ConsignesVRProps> = ({
  data: {
    allFile: { nodes: appStoresImages },
    site: { siteMetadata: siteInfo },
  },
  pageContext,
}) => {
  const { t } = useTranslation('instructions-vr');

  const appStoresImagesForCurrentLocale = appStoresImages.filter((img) =>
    img.name.toLowerCase().endsWith('_' + pageContext.locale),
  );
  interface AppStoresImagesForLocaleObject {
    [key: string]: GatsbyImageFileWithName;
  }
  const appStoresImagesForLocale: AppStoresImagesForLocaleObject = {};
  appStoresImagesForCurrentLocale.forEach((img) => {
    appStoresImagesForLocale[img.name] = img;
  });
  const androidStore =
    appStoresImagesForLocale[`android-store_${pageContext.locale.toUpperCase()}`].childImageSharp
      .gatsbyImageData;
  const appleStore =
    appStoresImagesForLocale[`apple-store_${pageContext.locale.toUpperCase()}`].childImageSharp
      .gatsbyImageData;

  const location = useLocation();
  // Use siteInfo as callback because location.origin is undefined in reacteev.com
  const origin = location.origin || siteInfo.siteUrl;
  const vtsplayerUrl = function (vstFile: string): string {
    return `com-sf-vtsplayer://import-vts?version=2022-09-08+09%3A08%3A14&ppi=owt1hoj3uoh60g27&autolaunch=true&forceupdate=true&url=${origin}/virtual-training-suite/${vstFile}`;
  };

  return (
    <DefaultLayout
      headTitle={t('head-title')}
      headDescription={t('head-description')}
      lang={pageContext.locale}
      wrapInContainer
    >
      <h1 className="pt-10 text-black">{t('title')}</h1>
      <h2 className="text-center text-black">{t('sub-title')}</h2>
      <p className="text-center text-black">
        <Trans t={t} i18nKey="warning-tips">
          warning-tips
        </Trans>
      </p>
      <ol className="text-black">
        <Step step={t('step-1.step')} t={t} i18nKey="step-1.title" />
        <Step step={t('step-2.step')} t={t} i18nKey="step-2.title" />
        <Step step={t('step-3.step')} t={t} i18nKey="step-3.title" />
        <Step step={t('step-4.step')} t={t} i18nKey="step-4.title" />
        <Step step={t('step-5.step')} t={t} i18nKey="step-5.title" />
        <Step step={t('step-6.step')} t={t} i18nKey="step-6.title">
          <ul className="list-disc pl-6">
            <li>
              <Trans t={t} i18nKey="step-6.instructions.value-1">
                step-6.instructions.value-1
              </Trans>
            </li>
            <li>
              <Trans t={t} i18nKey="step-6.instructions.value-2">
                step-6.instructions.value-2
              </Trans>
            </li>
          </ul>
        </Step>
      </ol>
      <div className="p-14 text-center">
        <h2 className="text-2xl font-semibold text-black sm:text-3xl">
          {t('downloads.player.title')}
        </h2>
        <a
          href="https://play.google.com/store/apps/details?id=com.seriousfactory.vtsplayer"
          className="mx-4 my-5 inline-block"
        >
          <GatsbyImage image={androidStore} alt={t('downloads.player.link-title-android')} />
        </a>
        <a
          href="https://itunes.apple.com/fr/app/vts-player/id1196791564"
          className="mx-4 my-5 inline-block"
        >
          <GatsbyImage image={appleStore} alt={t('downloads.player.link-title-apple')} />
        </a>
      </div>
      <div className="bg-black p-14 text-center text-white">
        <h2 className="text-2xl font-semibold sm:text-3xl">{t('downloads.experience.title')}</h2>
        <Button
          href={vtsplayerUrl('XP_Agile_en_Sei.vtsand')}
          text={t('downloads.experience.link-title-android')}
        />
        <Button
          href={vtsplayerUrl('XP_Agile_en_Sei.vtsios')}
          text={t('downloads.experience.link-title-apple')}
        />
      </div>
      <div className="my-8 text-center text-black">
        <Trans
          t={t}
          i18nKey="contact-us"
          components={{
            underline: <u />,
            // eslint-disable-next-line jsx-a11y/anchor-has-content
            email: <a href="mailto:communication@reacteev.com?subject=Consignes%20VR" />,
          }}
        >
          In case of issues,{' '}
          <strong>
            <u>
              <a href="mailto:communication@reacteev.com?subject=Consignes%20VR">contact us!</a>
            </u>
          </strong>
        </Trans>
      </div>
    </DefaultLayout>
  );
};

export default ConsignesVR;
